import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'

import image0 from '../images/fashion/DSCF3960-Edit.jpg'
import image1 from '../images/fashion/DSCF2634.JPG'
import image2 from '../images/fashion/DSCF3006.jpg'
import image3 from '../images/fashion/DSCF3238-Edit.jpeg'
import image4 from '../images/fashion/DSCF4017.jpg'
import image5 from '../images/fashion/DSCF3974-Edit.jpg'
import image6 from '../images/fashion/DSCF2207.JPG'

function FashionPortfolio() {
    return (
        <div className='container mx-auto'>
            <div className='container flex flex-row flex-wrap gap-10 grid lg:grid-cols-2 sm:grid-cols-1'>
                <LazyLoadImage src={image0} alt="img" effect='blur'/>
                <LazyLoadImage src={image1} alt="img" effect='blur'/>
                <LazyLoadImage src={image2} alt="img" effect='blur'/>
                <LazyLoadImage src={image3} alt="img" effect='blur'/>
                <LazyLoadImage src={image4} alt="img" effect='blur'/>
                <LazyLoadImage src={image5} alt="img" effect='blur'/>
                <LazyLoadImage src={image6} alt="img" effect='blur'/>
                
            </div>
        </div>
    )
    
}

export default FashionPortfolio