import React, { useEffect, useState } from 'react';

export const DateTime = () => {

    let [date, setDate] = useState(new Date());

    useEffect(() => {
      let timer = setInterval(()=>setDate(new Date()), 1000)
    
      return function cleanup() {
        clearInterval(timer)
      }
    });

    return(
        <div>
            <p className='font-sans italic'>{date.toLocaleTimeString("en-US", { hour12: false })}</p>
        </div>
    )
    
}

export default DateTime;