import "./index.css"
import Main from "./components/Main";

import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="p-4">
        <div className="relative py-10"><Main/></div>
        <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-sm text-red-600 text-center"><Navbar/></div>
        <div className="fixed top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2 text-sm font-mono text-red-900 text-center"></div>
    </div>

  );
}

export default App;
