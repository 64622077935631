import { NavLink } from "react-router-dom";

function Sidebar() {
    return (
        <div>
            <div className="text-bold">
                <NavLink to="/Home" className={({ isActive }) =>
                isActive ? "hover:italic font-['MyFont']" : "hover:italic font-['MyFont']"
            }>KURTIS LAW</NavLink>
            </div>
        </div>
        
    )
}




export default Sidebar;