import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import image0 from '../images/event/52426543422_b943f39e17_o.jpg'
import image1 from '../images/event/uoft-justice-fireside-47_52449524372_o.jpg'
import image2 from '../images/event/uoft-justice-fireside-122_52450318674_o.jpg'
import image3 from '../images/event/uoft-justice-fireside-70_52449524852_o.jpg'
import image5 from '../images/event/uoft-justice-fireside-30_52450494765_o.jpg'
import image6 from '../images/event/uoft-justice-fireside-113_52450496410_o.jpg'
import image8 from '../images/event/uoft-justice-fireside-51_52449524497_o.jpg'
import image9 from '../images/event/52427568893_89fb62c744_o.jpg'
import image10 from '../images/event/52427504550_cd55f96acc_o.jpg'
import image11 from '../images/event/52427568688_d91023bbde_o.jpg'
import image13 from '../images/event/52427569278_84e4f9cfc9_o.jpg'
import image14 from '../images/event/uoft-justice-fireside-146_52449523897_o.jpg'
import image15 from '../images/event/52427048391_2df6026e5e_o.jpg'
import image16 from '../images/event/uoft-justice-fireside-44_52450494915_o.jpg'
import image17 from '../images/event/uoft-justice-fireside-71_52450047896_o.jpg'
import image18 from '../images/event/uoft-justice-fireside-48_52450495005_o.jpg'
import image19 from '../images/event/uoft-justice-fireside-69_52449524847_o.jpg'
import image20 from '../images/event/uoft-justice-fireside-37_52450319354_o.jpg'
import image21 from '../images/event/uoft-justice-fireside-145_52450046866_o.jpg'

function EventPortfolio() {
    return (
        <div className='container mx-auto'>
            <div className='container flex flex-row flex-wrap gap-10 grid lg:grid-cols-2 sm:grid-cols-1'>
            
            <LazyLoadImage src={image2} alt='img' effect='blur'/>
            <LazyLoadImage src={image3} alt='img' effect='blur'/>
            <LazyLoadImage src={image5} alt='img' effect='blur'/>
            <LazyLoadImage src={image6} alt='img' effect='blur'/>
            <LazyLoadImage src={image8} alt='img' effect='blur'/>
            <LazyLoadImage src={image9} alt='img' effect='blur'/>
            <LazyLoadImage src={image10} alt='img' effect='blur'/>
            <LazyLoadImage src={image11} alt='img' effect='blur'/>
            <LazyLoadImage src={image0} alt='img' effect='blur'/>
            <LazyLoadImage src={image13} alt='img' effect='blur'/>
            <LazyLoadImage src={image15} alt='img' effect='blur'/>
            <LazyLoadImage src={image1} alt='img' effect='blur'/>
            <LazyLoadImage src={image16} alt='img' effect='blur'/>  
            <LazyLoadImage src={image17} alt='img' effect='blur'/>
            <LazyLoadImage src={image14} alt='img' effect='blur'/>
            <LazyLoadImage src={image18} alt='img' effect='blur'/>
            <LazyLoadImage src={image19} alt='img' effect='blur'/>
            <LazyLoadImage src={image20} alt='img' effect='blur'/>
            <LazyLoadImage src={image21} alt='img' effect='blur'/>
            
            </div>
        </div>
        
    )
}

export default EventPortfolio;
