import image1 from "../images/about/bio.jpg"

import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css'

function About() {
    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-2 pl-10">
                <div>
                    <p className="text-sm">
                        BASED IN TORONTO.
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <a className="hover:italic underline decoration-red-500" href="mailto: kurtislaww@gmail.com">KURTISLAWW [@] GMAIL.COM</a>
                        
                    </p>
                    

                </div>
                <div className="">
                    <LazyLoadImage effect='blur' src={image1}/>
                </div>
            </div>
        </div>
        
    )
}




export default About;