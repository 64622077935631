import DateTime from "./Clock"
import Sidebar from "./Sidebar"
import Selector from "./Selector"

function Navbar() {
    return (
        <div>
            <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1"> 
                <div className="">
                <Sidebar />
                </div>
            
                <div className="">
                <DateTime/>
                </div>
                <div className="flex-nowrap">
                <Selector />
                </div>
            </div>
        </div>
    )
}

export default Navbar

// flex lg:justify-center md:justify-center sm:justify-center
// flex lg:justify-end md:justify-end sm:justify-end