import image1 from '../images/street/soju-403.jpg'
import image2 from '../images/street/DSCF1758.JPG.jpg'
import image3 from '../images/street/DSCF3115.JPG'
import image4 from '../images/street/DSCF8601 (1).JPG'
import image5 from '../images/street/DSCF7821.jpg'
import image7 from '../images/street/DSCF8550.JPG.jpg'
import image8 from '../images/street/soju-565.jpg'
import image9 from '../images/street/DSCF5010.jpg'
import image10 from '../images/street/DSCF1704.JPG.jpg'
import image11 from '../images/street/DSCF7823.jpg'
import image12 from '../images/street/DSCF3377.jpg'
import image13 from '../images/street/DSCF1792-cinestill.jpg'
import image14 from '../images/street/max-bday-24.jpg'
import image15 from '../images/street/DSCF0727.JPG'
import image16 from '../images/street/DSCF8565.JPG'
import image17 from '../images/street/soju-454.jpg'
import image18 from '../images/street/DSCF5685.JPG'
import image19 from '../images/street/DSCF1018.JPG'
import image20 from '../images/street/DSCF0629 (1).jpg'
import image21 from '../images/street/DSCF0116.JPG'
import image22 from '../images/street/DSCF1008 (1).jpg'
import image23 from '../images/street/soju-479.jpg'
import image24 from '../images/street/soju-450.jpg'
import image25 from '../images/street/DSCF0588.JPG'
import image26 from '../images/street/max-5 (1).jpg'
import image27 from '../images/street/soju-224.jpg'
import image28 from '../images/street/DSCF0301 (1).jpg'
import image29 from '../images/street/soju-593.jpg'
import image30 from '../images/street/DSCF1352 (1).JPG'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

function StreetPortfolio() {
    return (
        <div className='container mx-auto'>
            <div className='container flex flex-row flex-wrap gap-10 grid lg:grid-cols-2 sm:grid-cols-1'>
            <LazyLoadImage src={image3} alt='img' effect='blur'/>
            <LazyLoadImage src={image4} alt='img' effect='blur'/>
            <LazyLoadImage src={image5} alt='img' effect='blur'/>
            <LazyLoadImage src={image7} alt='img' effect='blur'/>
            <LazyLoadImage src={image8} alt='img' effect='blur'/>
            <LazyLoadImage src={image9} alt='img' effect='blur'/>
            <LazyLoadImage src={image10} alt='img' effect='blur'/>
            <LazyLoadImage src={image1} alt='img' effect='blur'/>
            <LazyLoadImage src={image2} alt='img' effect='blur'/>
            <LazyLoadImage src={image11} alt='img' effect='blur'/>
            <LazyLoadImage src={image12} alt='img' effect='blur'/>
            <LazyLoadImage src={image13} alt='img' effect='blur'/>
            <LazyLoadImage src={image14} alt='img' effect='blur'/>
            <LazyLoadImage src={image15} alt='img' effect='blur'/>
            <LazyLoadImage src={image16} alt='img' effect='blur'/>
            <LazyLoadImage src={image17} alt='img' effect='blur'/>
            <LazyLoadImage src={image18} alt='img' effect='blur'/>
            <LazyLoadImage src={image19} alt='img' effect='blur'/>
            <LazyLoadImage src={image20} alt='img' effect='blur'/>
            <LazyLoadImage src={image21} alt='img' effect='blur'/>
            <LazyLoadImage src={image22} alt='img' effect='blur'/>
            <LazyLoadImage src={image23} alt='img' effect='blur'/>
            <LazyLoadImage src={image24} alt='img' effect='blur'/>
            <LazyLoadImage src={image25} alt='img' effect='blur'/>
            <LazyLoadImage src={image26} alt='img' effect='blur'/>
            <LazyLoadImage src={image27} alt='img' effect='blur'/>
            <LazyLoadImage src={image28} alt='img' effect='blur'/>
            <LazyLoadImage src={image29} alt='img' effect='blur'/>
            <LazyLoadImage src={image30} alt='img' effect='blur'/>
                
            </div>
        </div>
    )
}

export default StreetPortfolio;
