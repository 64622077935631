import { NavLink } from "react-router-dom";

function Selector() {
    return (
        <div className="flex-nowrap whitespace-nowrap">
            [ <NavLink to="/fashion"
                className={({ isActive }) =>
                    isActive ? "underline" : ""
                }
            >
                fashion
            </NavLink>{" "} - <NavLink
                to="/street"
                className={({ isActive }) =>
                    isActive ? "underline" : ""
                }
            >
                street
            </NavLink> - <NavLink to="/event" className={({ isActive }) =>
                isActive ? "underline" : ""
            }
            >
                event
            </NavLink> - <NavLink to="/about" className={({ isActive }) =>
                isActive ? "underline" : ""
            }
            >
                info
            </NavLink> ]
        </div>
    );
}

export default Selector;
